import FsLightbox from "fslightbox-react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { useState } from "react";
import "./CSS/About.css";
import certificateImages from "../assets/Routes/AllCertificate";
import Education from "./Education";

function About() {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Aarthipriya A | UI Developer | About</title>
        </Helmet>
      </HelmetProvider>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={certificateImages}
        slide={lightboxController.slide}
      />
      <Container fluid className="about-wrapper">
        <div className="about-left animate__animated animate__zoomIn">
          <h3>About</h3>
          <h4>
            ───&nbsp;&nbsp;Page <strong>02</strong>
          </h4>
        </div>
        <div className="about-right animate__animated animate__fadeIn animate__slower py-3">
          <p>
            {/* Hi, This is Aarthipriya and I am currently seeking job as UI developer. I've 10 months experience in UI development. I learnt Wordpress with help of Udemy course. And I've got an internship at Techax Labs LLP during from jun'2023 to jul'2023.  I am enthusiastic, self-motivated, reliable, responsible person. If I got hired I could spend my time to provide valuable output as expected.I love exploring new technologies and being a practitioner, I like to stay on top of latest trends. */}
            Hello, my name is Aarthipriya and I am  graduate with a
            Bachelor's degree in Chemistry. However, my passion for technology
            and programming led me to explore new opportunities in the field of
            Frontend UI development. Through online education platforms, I have
            gained knowledge in various programming languages such as HTML, CSS,
            JavaScript, ReactJS, and Bootstrap. 
            <br /><br />In August 2021, I was fortunate
            enough to join Searchblox Software Inc as a Frontend UI Trainee,
            where I have been work remotely. This experience has allowed me to
            apply my skills in a professional setting and enhance my knowledge
            in UI technologies. I am proficient in HTML, CSS, JavaScript, React,
            and Bootstrap, and I am also familiar with GitHub. <br /><br />In addition to my
            technical skills, I am a quick learner and have a strong passion for
            continuous learning and growth. I am currently at a beginner level
            in Figma and WordPress, but I am eager to expand my knowledge and
            expertise in these areas. In fact, I have completed a certification
            course in WordPress through Udemy. <br /><br />I am actively seeking new
            opportunities to further my career in Frontend UI development. I am
            a dedicated and hardworking individual, and I am confident that my
            skills and experience make me a valuable
          </p>

          <h4>Certificates</h4>
          <span>─────</span>
          <div className="certificates-wrapper">
            {certificateImages
              .reduce((rows, src, index) => {
                if (index % 3 === 0) rows.push([]);
                rows[rows.length - 1].push(
                  <div className="col certificates-ratio gap-image" key={src}>
                    <img
                      className="shadow"
                      loading="lazy"
                      src={src}
                      alt={`Certificate ${index + 1}`}
                      onClick={() => openLightboxOnSlide(index + 1)}
                    />
                  </div>
                );
                return rows;
              }, [])
              .map((row, index) => (
                <div className="row my-4" key={index}>
                  {row}
                </div>
              ))}
          </div>
          <h4>My Journey</h4>
          <span>─────</span>
          <Education />
        </div>
      </Container>
      <div className="">
        <div className=" animate__animated animate__fadeIn animate__slower py-3">
          <div className="row my-4"></div>
        </div>
      </div>
    </>
  );
}

export default About;
