const allProjects = [
  {
    title: 'Todo App',
    img: require('../img/WP_projects/todo.png'),
    description:
      'The React-based to-do app is a project designed to help users manage their tasks efficiently. Sculpted with HTML, CSS, JS, Reactjs.',
    websiteUrl: 'https://todo-app-8ff.pages.dev/',
    githubUrl: 'https://github.com/aarthipriya2021/todo_app',
    techStack: ['React', 'HTML5', 'CSS'],
  },
  {
    title: 'NexusNova',
    img: require('../img/WP_projects/nexusnova.png'),
    description:
      'NexusNova, A cutting-edge digital marketing agency website meticulously crafted with HTML, CSS, and JavaScript, React and Bootstrap.',
    websiteUrl: 'https://nexusnova-app.pages.dev/',
    githubUrl: 'https://github.com/aarthipriya2021/nexusnova_app',
    techStack: ['React', 'Bootstrap', 'HTML5', 'CSS'],
  },
  {
    title: 'Restro',
    img: require('../img/WP_projects/restro.png'),
    description:
      'Restro, This project showcases the seamless integration of HTML, CSS, and React technologies to deliver an engaging and responsive user interface for a single page restaurant-themed application.',
    websiteUrl: 'https://restro-app.pages.dev/',
    githubUrl: 'https://github.com/aarthipriya2021/restro_app',
    techStack: ['React','React-Router', 'HTML', 'CSS'],
  },
  {
    title: 'Gameocrypt',
    img: require('../img/WP_projects/gameocrypt_img.png'),
    description:
      'Gameocrypt, An crypto gaming related website designed with React, leveraging React for seamless single-page navigation. It\'ll looks like Landing page. ',
    websiteUrl: 'https://seemly-queen.surge.sh/',
    githubUrl: 'https://github.com/aarthipriya2021/gameocrypt',
    techStack: ['React', 'HTML5', 'CSS'],
  },
  {
    title: 'ToneTemple',
    img: require('../img/WP_projects/tonetemple.png'),
    description:
      'ToneTemple showcases the seamless integration of HTML, CSS, and React technologies to deliver an engaging and responsive user interface for fitness themed application. ',
    websiteUrl: 'https://free-beginner.surge.sh/',
    githubUrl: 'https://github.com/aarthipriya2021/tonetemple',
    techStack: ['React','React-Router', 'HTML5', 'CSS'],
  },
  {
    title: 'PlayTube',
    img: require('../img/WP_projects/playtube.png'),
    description:
      'PlayTube, It is an video-sharing platform crafted with HTML, CSS, and JavaScript, powered by React for a dynamic user interface. Utilizing React Router and Axios, PlayTube ensures smooth navigation and seamless content streaming, providing an immersive and engaging experience for users. ',
    websiteUrl: 'https://fortunate-respect.surge.sh/',
    githubUrl: 'https://github.com/aarthipriya2021/playtube_app',
    techStack: ['React', 'React-Router', 'HTML', 'CSS'],
  },
];

export default allProjects;
