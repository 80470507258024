import React, { Component } from 'react';
import classes from './CSS/Education.module.css';
import "animate.css/animate.min.css";
import { MdSchool } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { FaSchool } from 'react-icons/fa';

class Education extends Component {
    render() {
        return (
                <div className={classes.box} id="education">
                     <span  className={classes.head}></span>
                    <section className={classes.container}>
                        <div className={classes.container_content}>
                            <div className={classes.row}>
                                <div className={classes.row_md_12}>
                                    <div className={classes.timeline_centered}>
                                            <article className={classes.timeline_entry}>
                                                <div className={`${classes.timeline_icon} ${classes.timeline_icon_2}`} >
                                                    <FaSchool />     
                                                </div>
                                                <div className={classes.label}>
                                                <h2 >Higher Education  <span>2014-2016</span></h2>
                                                <p>I have completed my higher education from ZKM Hr Secondary School with major subjects as Physics,Chemistry & Maths with 77.5 percentage in State board. </p>
                                                </div>
                                            </article>
                                            <article className={classes.timeline_entry}>
                                                <div className={classes.timeline_icon} >
                                                <MdSchool />
                                                </div>
                                                <div className={classes.label}>
                                                <h2 >Undergraduation at CPA College <span>2016-2019</span></h2>
                                                 {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                <p>I have completed my undergraduation in B.Sc(Chemistry) from <a href='https://cpacollege.org/' target="_blank">CPAC </a>. </p>
                                                </div>
                                            </article>
                                            <article>
                                                <div className={`${classes.timeline_icon} ${classes.timeline_icon_4}`} >
                                                    <MdWork />     
                                                </div>
                                                <div className={classes.label}>
                                                <h2 className='work'><b>Work</b> - Trainee Teacher <span>November 2019 - April 2020</span></h2>
                                                <p>I have worked as a trainee  teacher for six months during 2019 .</p>
                                                </div>

                                                <div className={classes.label}>
                                                    <h2 ><b>Work</b> -  Frontend UI Trainee  <span>August 2021 - June 2022</span></h2>
                                                        <p>I have worked as a Frontend UI Trainee at SearchBlox Software, Inc . </p>
                                                    <h2 className={classes.responsibilities}>Responsibilities :</h2>
                                                        <li>Collaborated with team members to achieve target results.</li>
                                                        <li>Used Frontend technologies like HTML,CSS, JS, React JS, Sass to create the frontend portion of application.</li>
                                                        <li>Used GIT tool, for build application fast and reliable.</li>
                                                        <li>Maintained the code readability, and responsiveness of an application.</li>
                                                        <li>Attend meetings with UI development teams, project managers, and customers to ensure alignment with project specifications.</li>
                                                </div>
                                                <div className={classes.label}>
                                                    <h2 ><b>Work</b> -  WordPress Developer Intern  <span>June 2023 - July 2023</span></h2>
                                                        <p>I have worked as a WordPress Developer Intern at Techax Labs LLP. </p>
                                                    <h2 className={classes.responsibilities}>Responsibilities :</h2>
                                                        <li> Developing the WordPress demo websites.</li>
                                                        <li> Designing the screens and templates based on WordPress.</li>
                                                        <li> Create the website pages using the Elementor and various plugins.</li>
                                                        <li> Integrate plugins and functionalities in the WordPress website.</li>
                                                </div>
                                                <div className={classes.timeline_entry_inner}><div  className={classes.timeline_icon_3||classes.color_none}>
                                                </div>
                                                </div>
                                            </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
     
        )
    }
}

export default Education;
